  <div class="footer">
    <div class="footer-row">
      <a href="https://www.facebook.com/profile.php?id=100091249451156" target="_blank"><i class="fa fa-facebook"></i></a>
      <a href="#"><i class="fa fa-instagram"></i></a>
      <a href="#"><i class="fa fa-youtube"></i></a>
      <a href="#"><i class="fa fa-twitter"></i></a>
    </div>

    <div class="footer-row">
      <ul>
        <li><a href="#">Contact us</a></li>
        <li><a href="#">Our Services</a></li>
        <li><a href="#">Privacy Policy</a></li>
        <li><a href="#">Terms & Conditions</a></li>
        <li><a href="#">Career</a></li>
      </ul>
    </div>

    <div class="footer-row">
       Copyright © {{ date | date: "yyyy" }}  - All rights reserved || Designed By: Kafeel Ahmad
    </div>
  </div>
