<nav
  class="navbar "
  style="background-color: #1c5790 !important"
>
  <!-- <a class="navbar-brand" href="#">Navbar</a> -->
  <div class="bg-dark">
    <!-- <img src="../../../assets/logo-color.png" height="80" width="90"> -->
    <img
      src="../../../assets/logo-no-background.png"
      height="30"
      width="130"
      class="logo"
    />
    <!-- main logo -->
  </div>
  <div class="text-light text-center mr-23">
    <span >Coming Soon !!!</span>
  </div>
  <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button> -->

  <!-- <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item active">
        <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link</a>
      </li>
      <li class="nav-item">
        <a class="nav-link disabled" href="#">Disabled</a>
      </li>
    </ul>
  </div> -->
</nav>
